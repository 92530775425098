/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import { unref as _unref, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "bar-custom-control__head"
};
var _hoisted_2 = {
  class: "bar-custom-control__icon"
};
var _hoisted_3 = {
  key: 0,
  class: "icon-success-check"
};
var _hoisted_4 = ["innerHTML"];
var _hoisted_5 = {
  class: "bar-custom-control__content"
};
import { useField } from "vee-validate";
import { Radio, Checkbox } from '@/js/functions.js';
export default {
  __name: 'BarCustomControl',
  props: {
    // 種類
    checkbox: Boolean,
    radio: Boolean,
    // radio checkbox 相關
    modelValue: [Boolean, String, Number, Array],
    value: [String, Number, Boolean],
    text: [String],
    name: {
      type: [String, Number],
      default: ''
    },
    required: Boolean,
    disabled: Boolean,
    // size
    size: String // size /

  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var Component = props.checkbox ? Checkbox : Radio;

    var _Component = Component(reactive({
      emit: emit,
      modelValue: computed(function () {
        return props.modelValue;
      }),
      value: computed(function () {
        return props.value;
      }),
      name: computed(function () {
        return props.name;
      }),
      required: computed(function () {
        return props.required;
      }),
      disabled: computed(function () {
        return props.disabled;
      })
    })),
        thismodelValue = _Component.modelValue,
        thisValue = _Component.value,
        thisName = _Component.name,
        thisRequired = _Component.required,
        thisDisabled = _Component.disabled,
        isSelected = _Component.isSelected,
        toggleCheck = _Component.toggleCheck; // validate


    var _useField = useField(props.name),
        thisErrMsg = _useField.errorMessage,
        name = _useField.name,
        value = _useField.value,
        meta = _useField.meta,
        errors = _useField.errors,
        resetField = _useField.resetField,
        validate = _useField.validate,
        handleChange = _useField.handleChange,
        handleBlur = _useField.handleBlur,
        setValidationState = _useField.setValidationState,
        checked = _useField.checked;

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("label", {
        class: _normalizeClass(["bar-custom-control", {
          'bar-custom-control_checked': _unref(isSelected),
          'bar-custom-control_checkbox': __props.checkbox,
          'bar-custom-control_radio': __props.radio,
          'bar-custom-control_disabled': __props.disabled
        }])
      }, [_createElementVNode("input", _mergeProps({
        type: "checkbox"
      }, {
        name: _unref(thisName),
        disabled: _unref(thisDisabled),
        required: _unref(thisRequired),
        'true-value': true,
        'false-value': false
      }, {
        onChange: _cache[0] || (_cache[0] = function ($event) {
          return _unref(toggleCheck)();
        })
      }), null, 16), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [__props.checkbox ? (_openBlock(), _createElementBlock("i", _hoisted_3)) : _createCommentVNode("", true)]), _createElementVNode("div", {
        class: "bar-custom-control__text",
        innerHTML: __props.text
      }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "default")])], 2);
    };
  }
};